<template>
  <div class="companies-map">
    <ve-bmap
      ref="mapRef"
      height="100%"
      :settings="chartSettings"
      :series="chartSeries"
      :tooltip="chartTooltip"
      :events="chartEvents"
    ></ve-bmap>
    <div class="chain-block-box" v-show="companyList.length > 0">
      Hash值区块链验证:
      <span v-if="!hashFlag" class="hash-value" @click="verifyHash">
        <i class="el-icon-thumb"></i>
        {{ hashVal }}
      </span>
      <span v-else class="hash-result" @click="verifyHash">数据无篡改</span>
    </div>
    <div class="company-list-box">
      <div style="text-align: right">
        <el-button
          v-if="companyListFlag"
          size="small"
          type="warning"
          icon="el-icon-minus"
          @click="companyListFlag = false"
          >收起</el-button
        >
        <el-button
          v-else
          size="small"
          type="primary"
          icon="el-icon-office-building"
          @click="companyListFlag = true"
          >展开</el-button
        >
      </div>
      <el-collapse-transition>
        <div v-show="companyListFlag" class="company-list">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <el-input
                style="width: 100%"
                v-model="listQuery.company_name"
                size="mini"
                placeholder="输入关键字搜索"
                @input="handleInput"
              >
                <template slot="prepend">生产经营者名称</template>
              </el-input>
            </div>
            <!-- <div>
              <el-table
                style="background:transparent"
                border
                fit
                :data="companyList.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                :show-header="false"
                highlight-current-row
                :max-height="500"
                @row-click="handleRowClick"
              >
                <el-table-column prop="name" align="center"></el-table-column>
              </el-table>
            </div>-->
            <div>
              <el-table
                style="background: transparent"
                border
                fit
                :data="companyList"
                :show-header="false"
                highlight-current-row
                :max-height="500"
                @row-click="handleRowClick"
              >
                <el-table-column
                  prop="company_name"
                  align="center"
                ></el-table-column>
              </el-table>
              <el-pagination
                small
                background
                hide-on-single-page
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="parseInt(listQuery.pager_openset)"
                layout=" prev, pager, next"
                :total="total"
              ></el-pagination>
            </div>
          </el-card>
        </div>
      </el-collapse-transition>
    </div>
    <div style="display: none">
      <div ref="customInfowindow">
        <h4 style="margin: 0 0 5px 0; padding: 0.2em 0">
          名称:{{ companyInfo.company_name }}
        </h4>
        <div style="margin-top: 5px">
          <i class="el-icon-location"></i>
          <span>地址:{{ companyInfo.address }}</span>
        </div>
        <div style="margin-top: 10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <!-- <div class="grid-content bg-purple">
                <el-image
                  v-if="companyInfo.blc_credit"
                  :src="companyInfo.blc_credit"
                  style="width:100px;height:150px;"
                  :preview-src-list="[companyInfo.blc_credit]"
                ></el-image>
                <span v-else style="color:#F56C6C">无营业执照</span>
              </div>-->
            </el-col>
            <el-col :span="12">
              <!-- <div class="grid-content bg-purple">
                <el-image
                  v-if="companyInfo.sc_credit"
                  style="width:100px;height:150px;"
                  :src="companyInfo.sc_credit"
                  :preview-src-list="[companyInfo.sc_credit]"
                ></el-image>
                <span v-else style="color:#F56C6C">无生产经营许可证</span>
              </div>-->
            </el-col>
          </el-row>
        </div>
        <el-button
          style="margin-top: 10px"
          type="primary"
          @click="handleSup"
          size="small"
          v-if="this.link"
          >冷库数字化</el-button
        >
        <el-button
          style="margin-top: 10px"
          type="success"
          @click="handleRecord"
          size="small"
          >收发货详情</el-button
        >
        <el-button
          style="margin-top: 10px"
          type="warning"
          @click="handleStorage"
          size="small"
          >库存详情</el-button
        >
        <!--        <br />-->
        <el-button
          style="margin-top: 10px"
          type="primary"
          @click="handleUnit"
          size="small"
          >供销单位</el-button
        >
      </div>
    </div>

    <el-dialog title="监管" :visible.sync="supDialogVisible" width="25%">
      <el-form label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="评级">
              <el-select v-model="level" placeholder="请选择">
                <el-option
                  v-for="item in levelOptions"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="margin-bottom: 15px">
            <el-form-item label="违法违规">
              <el-select v-model="reason" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="margin-bottom: 15px">
            <el-form-item label="监管文书上传">
              <el-upload
                class="upload-demo"
                :action="actionPath"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :limit="1"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="supDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleCreateSup">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="记录"
      :visible.sync="recordDialogVisible"
      width="75%"
      @close="restFilterRecordForm"
    >
      <el-form inline :model="filterRecordForm">
        <el-form-item size="small" style="margin-right: 30px">
          <el-input
            size="small"
            v-model="filterRecordForm.goodsName"
            placeholder="商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item size="small" style="margin-right: 30px">
          <el-input
            size="small"
            v-model="filterRecordForm.batchNumber"
            style="width: 150px"
            placeholder="批号"
          ></el-input>
        </el-form-item>
        <el-form-item size="small" style="margin-right: 30px">
          <el-input
            size="small"
            v-model="filterRecordForm.gtin"
            placeholder="货物品码"
          ></el-input>
        </el-form-item>
        <el-form-item size="small" style="margin-right: 30px">
          <el-input
            size="small"
            v-model="filterRecordForm.carNumber"
            placeholder="车牌号"
          ></el-input>
        </el-form-item>
        <el-form-item size="small" style="margin-right: 30px">
            <el-radio-group v-model="filterRecordForm.goodsType">
               <el-radio :label="3">全部</el-radio>
                <el-radio :label="1">进口</el-radio>
                <el-radio :label="2">国产</el-radio>
             </el-radio-group>
        </el-form-item>
        <el-form-item size="small">
          <el-button type="primary" @click="handleRecord" size="small"
            >筛选</el-button
          >
        </el-form-item>
      </el-form>
      <el-tabs type="border-card">
        <el-tab-pane label="发货记录">
          <el-table
            border
            fit
            :data="recordData.send_out_records"
            empty-text="暂无数据"
            height="400"
            @sort-change="recordSortChange($event, 1)"
          >
            <el-table-column
              align="center"
              label="商品类型"
            >
              <template slot-scope="scope">
                <span>{{scope.row.goodsCategory | infoFilter}}</span>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              property="createdTime"
              label="发货日期"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsName"
              label="商品名称"
            ></el-table-column>
            <el-table-column align="center" label="二维码值/条形码">
              <template slot-scope="scope">
                <span>{{ scope.row.gtin }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="batchNumber"
              label="批号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="toCompanyName"
              label="收货方"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsWeight"
              label="统计"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsUnitPrice"
              label="发货单价(元)"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsTotalPrice"
              label="发货总价(元)"
            ></el-table-column>
            <!-- <el-table-column align="center" label="状态">
              <template slot-scope="scope">
                <el-tag :type="scope.row.status | tagFormat">{{
                  scope.row.status | statusFormat
                }}</el-tag>
              </template>
            </el-table-column> -->
            <el-table-column
              align="center"
              label="区块链Hash值"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span
                  v-if="!scope.row.hashFlag"
                  @click="scope.row.hashFlag = !scope.row.hashFlag"
                  style="cursor: pointer"
                  >{{ scope.row.hashVal }}</span
                >
                <span
                  v-else
                  style="color: #67c23a; cursor: pointer"
                  @click="scope.row.hashFlag = !scope.row.hashFlag"
                  >数据无篡改</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="carNumber"
              label="车牌号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="consignerName"
              label="操作人员"
            ></el-table-column>
            <el-table-column
              align="center"
              property="remark"
              label="备注"
            ></el-table-column>
            <el-table-column align="center" label="操作" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleViewPic(scope.row)"
                  >查看图片</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <el-pagination
              background
              hide-on-single-page
              :page-size="20"
              @current-change="handleCurrentChangeSr"
              :current-page="srlistPage"
              layout="total, prev, pager, next, jumper"
              :total="recordData.send_out_records_total"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="收货记录">
          <el-table
            border
            fit
            :data="recordData.received_records"
            empty-text="暂无数据"
            height="400"
            @sort-change="recordSortChange($event, 2)"
          >
            <el-table-column
                    align="center"
                    label="商品类型"
            >
              <template slot-scope="scope">
                <span>{{scope.row.goodsCategory | infoFilter}}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="createdTime"
              label="收货日期"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsName"
              label="商品名称"
            ></el-table-column>
            <el-table-column align="center" label="二维码值/条形码">
              <template slot-scope="scope">
                <span>{{ scope.row.gtin }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="batchNumber"
              label="批号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="fromCompanyName"
              label="发货方"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsWeight"
              label="统计"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsUnitPrice"
              label="发货单价(元)"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsTotalPrice"
              label="发货总价(元)"
            ></el-table-column>
            <el-table-column
              align="center"
              property="toGoodsUnitPrice"
              label="收货单价(元)"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              align="center"
              property="toGoodsTotalPrice"
              label="收货总价(元)"
            ></el-table-column>
            <el-table-column
              align="center"
              label="区块链Hash值"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span
                  v-if="!scope.row.hashFlag"
                  @click="scope.row.hashFlag = !scope.row.hashFlag"
                  style="cursor: pointer"
                  >{{ scope.row.hashVal }}</span
                >
                <span
                  v-else
                  style="color: #67c23a; cursor: pointer"
                  @click="scope.row.hashFlag = !scope.row.hashFlag"
                  >数据无篡改</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="carNumber"
              label="车牌号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="consignerName"
              label="操作人员"
            ></el-table-column>
            <el-table-column
              align="center"
              property="remark"
              label="备注"
            ></el-table-column>
            <el-table-column align="center" label="操作" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleViewPic(scope.row)"
                  >查看图片</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <el-pagination
              background
              hide-on-single-page
              :page-size="20"
              @current-change="handleCurrentChangeRr"
              :current-page="rrlistPage"
              layout="total, prev, pager, next, jumper"
              :total="recordData.received_records_total"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog
      title="库存"
      :visible.sync="storageDialogVisible"
      width="55%"
      @close="restFilterForm"
    >
      <el-form inline :model="filterForm">
        <el-form-item size="small">
          <el-input size="small" v-model="filterForm.goodsName" placeholder="商品名称"></el-input>
        </el-form-item>
        <el-form-item size="small">
          <el-input size="small" v-model="filterForm.gtin" placeholder="二维码值/条形码"></el-input>
        </el-form-item>
        <el-form-item size="small">
          <el-input size="small" v-model="filterForm.batchNumber" placeholder="批号"></el-input>
        </el-form-item>
        <el-form-item size="small">
          <el-checkbox
            v-model="filterForm.flag"
            :true-label="1"
            :false-label="0"
            @change="handleStorage"
          >以品类统计</el-checkbox>
        </el-form-item>
        <!-- <br /> -->
        <!-- <el-form-item label="按出厂时长排序" style="margin-right:30px;">
          <el-switch
            v-model="filterForm.time_sort"
            active-text="升序"
            inactive-text="降序"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="按库存量排序" style="margin-right:30px;">
          <el-switch
            v-model="filterForm.stock_sort"
            active-text="升序"
            inactive-text="降序"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>-->
        <el-form-item>
          <el-button size="small" type="primary" @click="handleStorage"
            >筛选</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        border
        fit
        :data="storageData"
        empty-text="暂无数据"
        height="350"
      >
        <el-table-column
          align="center"
          property="goodsName"
          label="商品名称"
        ></el-table-column>
        <el-table-column align="center" label="二维码值/条形码">
          <template slot-scope="scope">
            <span>{{ scope.row.gtin }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="batchNumber"
          label="批号"
          v-if="!filterForm.flag"
        ></el-table-column>
        <el-table-column
          align="center"
          property="fromWeight"
          label="发货重量"
        ></el-table-column>
        <el-table-column
          align="center"
          property="toWeight"
          label="收货重量"
        ></el-table-column>
        <el-table-column
                align="center"
                property="stock"
                label="库存"
        ></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="查看图片" :visible.sync="picDialogVisible" top="8vh">
      <div v-show="reportList.length > 0">
        <el-divider content-position="left">
          <el-tag>资证报告</el-tag>
        </el-divider>
        <el-row :gutter="10">
          <el-col :span="6" v-for="(item, index) in reportList" :key="index">
            <el-image
              style="height: 200px"
              :src="item"
              :preview-src-list="reportList"
            ></el-image>
          </el-col>
        </el-row>
      </div>
      <div v-show="attachList.length > 0">
        <el-divider content-position="left">
          <el-tag type="success">收发货实景</el-tag>
        </el-divider>
        <el-row :gutter="10">
          <el-col :span="6" v-for="(item, index) in attachList" :key="index">
            <el-image
              style="height: 200px"
              :src="item"
              :preview-src-list="attachList"
            ></el-image>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog title="供销单位" :visible.sync="unitDialogVisible" width="55%">
      <!-- <el-form inline :model="filterForm">
        <el-form-item size="small" label="商品名称">
          <el-input size="small" v-model="filterForm.company_name"></el-input>
        </el-form-item>
        <el-form-item size="small" label="批号">
          <el-input size="small" v-model="filterForm.batch_search"></el-input>
        </el-form-item>
        <el-form-item size="small" label="二维码值/条形码">
          <el-input size="small" v-model="filterForm.code_search"></el-input>
        </el-form-item>
        <el-form-item size="small" label="以品类统计">
          <el-checkbox
            v-model="filterForm.flag"
            :true-label="1"
            :false-label="0"
            @change="handleStorage"
          ></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="handleStorage"
            >筛选</el-button
          >
        </el-form-item>
      </el-form> -->
      <el-table border fit :data="unitData" empty-text="暂无数据" height="350">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form
              label-position="left"
              inline
              class="cp-table-expand"
              label-width="100px"
            >
              <el-form-item label="类型">
                <span>{{ props.row.companyType }}</span>
              </el-form-item>
              <el-form-item label="法定代表人">
                <span>{{ props.row.legalPersonName }}</span>
              </el-form-item>
              <!-- <el-form-item label="成立资本">
                <span>{{ props.row.bl_reg_capital }}</span>
              </el-form-item> -->
              <el-form-item label="营业执照" v-if="props.row.creditUrl">
                <el-button
                  type="primary"
                  size="mini"
                  @click="handleViewPic1(props.row.creditUrl)"
                  >查看</el-button
                >
              </el-form-item>
              <el-form-item label="成立日期">
                <span>{{ props.row.issueTime }}</span>
              </el-form-item>
              <el-form-item label="有效期至">
                <span>{{ props.row.unusefulTime }}</span>
              </el-form-item>
              <el-form-item label="身份证正面" v-if="props.row.idCardFontUrl">
                <el-button
                  type="primary"
                  size="mini"
                  @click="handleViewPic1(props.row.idCardFontUrl)"
                  >查看</el-button
                >
              </el-form-item>
              <el-form-item label="身份证背面" v-if="props.row.idCartBackUrl">
                <el-button
                  type="primary"
                  size="mini"
                  @click="handleViewPic1(props.row.idCartBackUrl)"
                  >查看</el-button
                >
              </el-form-item>
              <el-form-item label="联系人">
                <span>{{ props.row.contact_user }}</span>
              </el-form-item>
              <el-form-item label="联系人电话">
                <span>{{ props.row.mobilephone }}</span>
              </el-form-item>
              <el-form-item label="经营范围">
                <span>{{ props.row.businessNames }}</span>
              </el-form-item>
              <!-- <el-form-item label="省">
                <span>{{ props.row.province }}</span>
              </el-form-item>
              <el-form-item label="市">
                <span>{{ props.row.city }}</span>
              </el-form-item>
              <el-form-item label="区/县">
                <span>{{ props.row.area }}</span>
              </el-form-item>
              <el-form-item label="街道/镇">
                <span>{{ props.row.street }}</span>
              </el-form-item>
              <el-form-item label="居委会">
                <span>{{ props.row.village }}</span>
              </el-form-item>
              <el-form-item label="经营范围">
                <span>{{ props.row.bl_dom }}</span>
              </el-form-item> -->
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="companyName"
          label="公司名称"
        ></el-table-column>
        <el-table-column
          align="center"
          property="creditCode"
          label="统一社会信用代码"
        ></el-table-column>
      </el-table>
    </el-dialog>

    <el-image-viewer
      style="z-index: 9999"
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="[url]"
    />
  </div>
</template>

<script>
// import axios from "@/util/api.js";
import axios from "../../../util/api";
import { diffTime, deepClone, createHash } from "@/util/util";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { mapConfig } from "./custom_map_config.js";

export default {
  components: {
    ElImageViewer,
  },
  data() {
    this.chartSettings = {
      key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
      bmap: {
        center: [120, 30],
        zoom: 5,
        roam: true,
        enableMapClick: false,
        mapStyle: { styleJson: mapConfig },
      },
    };
    this.chartTooltip = {
      show: true,
      formatter: function (params, ticket, callback) {
        if (params.seriesType === "effectScatter") {
          return `${params.marker}${params.value[2]}`;
        } else if (params.seriesType === "lines") {
          return `${params.data.fromName} > ${params.data.toName}`;
        }
      },
    };
    let _this = this;
    this.chartEvents = {
      click: (v) => {
        _this.$nextTick((_) => {
          _this.companyInfo.company_name = v.value[2];
          _this.companyInfo.address = v.value[4];
          _this.companyId = v.value[3];
          var echarts = this.$refs.mapRef.echarts;
          var map = echarts.getModel().getComponent("bmap").getBMap();
          var point = new window.BMap.Point(v.value[0], v.value[1]);
          var infoWindow = new window.BMap.InfoWindow(
            _this.$refs.customInfowindow
          );
          // if (this.clickTimes == 0) {
          //   map.centerAndZoom(point, 14);
          // }
          map.setCenter(point);
          map.openInfoWindow(infoWindow, point);
          this.clickTimes++;
        });
      },
      finished: (_) => {
        // _this.getGeo();
      },
    };
    return {
      companyId: null,
      dataType: null,
      barcode: "",
      companyList: [],
      chartSeries: [],
      srlistPage: 1,
      rrlistPage: 1,
      recordData: {},
      companyInfo: {
        company_name: "",
        address: "",
      },
      storageData: [],
      unitData: [],
      filterForm: {
        flag: 0,
        goodsName: "",
        batchNumber: "",
        gtin: "",
      },
      filterRecordForm: {
        goodsName: "",
        gtin: "",
        batchNumber: "",
        carNumber: "",
        goodsType:5
      },
      recordDialogVisible: false,
      supDialogVisible: false,
      storageDialogVisible: false,
      unitDialogVisible: false,
      picDialogVisible: false,
      attachList: [],
      reportList: [],
      options: [
        {
          key: 1,
          value: "资证过期",
          label: "资证过期",
        },
        {
          key: 2,
          value: "未履行查验义务",
          label: "未履行查验义务",
        },
      ],
      levelOptions: [
        {
          value: "A级",
          label: "A级",
        },
        {
          value: "B级",
          label: "B级",
        },
        {
          value: "C级",
          label: "C级",
        },
      ],
      clickTimes: 0,
      level: "",
      reason: "",
      file: "",
      search: "",
      link:'',
      actionPath:
        process.env.VUE_APP_BASE_API + "/api/v1/department/sup_upload",
      baseUrl: process.env.VUE_APP_BASE_API,
      companyListFlag: true,
      hashFlag: false,
      hashVal: createHash(30),
      listQuery: {
        pager_offset: "0",
        pager_openset: "100",
      },
      currentPage: 1,
      total: null,
      url: "",
      showViewer: false,
    };
  },
  computed: {},
  created() {},
  filters: {
    tagFormat(status) {
      const tagMap = {
        0: "primary",
        1: "success",
      };
      return tagMap[status];
    },
    statusFormat(status) {
      const statusMap = {
        0: "未收货",
        1: "已收货",
      };
      return statusMap[status];
    },
    timeFormat(timeString) {
      return diffTime(timeString);
    },
    infoFilter(info) {
      let goodsCategory = JSON.parse(info);
      if (goodsCategory) {
        if (goodsCategory[0] == "国产" || goodsCategory[0] == "") {
          return "国产";
        } else if (goodsCategory[0] == "进口") {
          return "进口";
        }
      } else {
        return "国产";
      }
    },
  },
  mounted() {
    this.currentPage =
      parseInt(this.listQuery.pager_offset) /
        parseInt(this.listQuery.pager_openset) +
      1;
    this.initMap();
  },
  methods: {
    initMap() {
      axios
        .get(`/pc/company/company-map-list/2`, this.listQuery)
        .then((response) => {
          let res = response.data.company_list;
          this.total = response.data.pager_count;
          this.companyList = deepClone(res);
          let companiesData = this.convertData(res);
          let arr = [];
          companiesData.forEach((item, index) => {
            arr.push({
              type: "effectScatter",
              coordinateSystem: "bmap",
              zlevel: 2,
              rippleEffect: {
                //涟漪特效
                period: 4, //动画时间，值越小速度越快
                brushType: "stroke", //波纹绘制方式 stroke, fill
                scale: 4, //波纹圆环最大限制，值越大波纹越大
              },
              symbol: "circle",
              itemStyle: {
                normal: {
                  color: "#19d4ae",
                },
              },
              data: item,
            });
          });
          this.chartSeries = arr;
          this.getGeo();
        });
    },
    afterSet: function (echarts) {
      var bmap = echarts.getModel().getComponent("bmap").getBMap();
      bmap.addControl(new window.BMap.MapTypeControl());
    },
    handleSizeChange(val) {
      this.listQuery.pager_openset = val + "";
      this.initMap();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.listQuery.pager_offset =
        (val - 1) * this.listQuery.pager_openset + "";
      this.initMap();
    },
    restFilterForm() {
      this.filterForm = {
        flag: 0,
        goodsName: "",
        batchNumber: "",
        gtin: "",
      };
    },
    restFilterRecordForm() {
      this.filterRecordForm = {
        goodsName: "",
        gtin: "",
        batchNumber: "",
        carNumber: "",
        goodsType:3
      };
    },
    convertData(data) {
      let result = [];
      for (let i = 0; i < data.length; i++) {
        let formattedData = [
          [
            data[i].longitude,
            data[i].latitude,
            data[i].company_name,
            data[i].company_id,
            data[i].address,
          ],
        ];
        result.push(formattedData);
      }
      return result;
    },
    async handleRecord() {
      this.srlistPage = 1;
      this.rrlistPage = 1;
      await Promise.all([this.fetchSendRecords(), this.fetchReceiveRecords()]);
      this.recordDialogVisible = true;
    },
    async fetchSendRecords() {
      await this.$extraApi
        .get(`/v1/pc/area/receiptAndDeliveryData`, {
          companyId: this.companyId,
          ...this.filterRecordForm,
          type: 1,
          page: this.srlistPage,
        })
        .then((response) => {
          let res = response.data;
          res.forEach((item) => {
            item.hashFlag = false;
            item.hashVal = createHash(30);
          });
          this.$set(this.recordData, "send_out_records", res);
          this.$set(this.recordData, "send_out_records_total", response.total);
        });
    },
    async fetchReceiveRecords() {
      await this.$extraApi
        .get(`/v1/pc/area/receiptAndDeliveryData`, {
          companyId: this.companyId,
          ...this.filterRecordForm,
          type: 2,
          page: this.rrlistPage,
        })
        .then((response) => {
          let res = response.data;
          res.forEach((item) => {
            item.hashFlag = false;
            item.hashVal = createHash(30);
          });
          this.$set(this.recordData, "received_records", res);
          this.$set(this.recordData, "received_records_total", response.total);
        });
    },
    handleCurrentChangeSr(val) {
      this.srlistPage = val;
      this.fetchSendRecords();
    },
    handleCurrentChangeRr(val) {
      this.rrlistPage = val;
      this.fetchReceiveRecords();
    },
    handleStorage() {
      this.$extraApi
        .get(`/v1/pc/area/store`, {
          companyId: this.companyId,
          limit:1000,
          ...this.filterForm,
        })
        .then((response) => {
          let res = response.data;
          this.storageData = res;
          this.storageDialogVisible = true;
        });
    },
    handleUnit() {
      this.$extraApi.get(`/v1/pc/area/supply`, { companyId: this.companyId }).then((response) => {
        let res = response.data;
        this.unitData = res;
        this.unitDialogVisible = true;
      });
    },
    handleSup() {
      window.open("http://cllext.foodtrace.org.cn/#/login?cllcode="+this.link);
    },
    handleRemove(file, fileList) {
      this.file = "";
    },
    handlePreview(file) {},
    handleSuccess(file, fileList) {
      this.file = file.data.file_name;
    },
    async recordSortChange({ column, prop, order }, type) {
      this.filterRecordForm.sort_key = type;
      this.filterRecordForm.sort_status = order;
      await this.handleRecord();
    },
    handleCreateSup() {
      sup_record
        .save({
          company_id: this.companyId,
          level: this.level,
          reason: this.reason,
          file: this.file,
        })
        .then((response) => {
          this.$notify({
            title: "成功",
            message: "监管成功",
            type: "success",
          });
          this.supDialogVisible = false;
        });
    },
    sortChange({ column, prop, order }) {
      this.filterForm.sort_key = prop;
      order == "ascending"
        ? (this.filterForm.sort_status = 1)
        : (this.filterForm.sort_status = 0);
      this.handleStorage();
    },
    handleRowClick(row, column, event) {
      if (row.longitude && row.latitude) {
        this.$nextTick((_) => {
          this.companyInfo.company_name = row.company_name;
          this.companyInfo.address = row.address;
          this.companyId = row.company_id;
          this.link = row.phone;
          let echarts = this.$refs.mapRef.echarts;
          let map = echarts.getModel().getComponent("bmap").getBMap();
          let point = new window.BMap.Point(row.longitude, row.latitude);
          let infoWindow = new window.BMap.InfoWindow(
            this.$refs.customInfowindow
          );
          // if (this.clickTimes == 0) {
          //   map.centerAndZoom(point, 14);
          // }
          map.setCenter(point);
          map.openInfoWindow(infoWindow, point);
          this.clickTimes++;
        });
      } else {
        this.$message({
          message: "缺少定位信息",
          type: "warning",
        });
      }
    },
    getGeo() {
      setTimeout((_) => {
        let pointArr = [];
        this.companyList.forEach((item) => {
          pointArr.push(new window.BMap.Point(item.longitude, item.latitude));
        });
        let echarts = this.$refs.mapRef.echarts;
        let map = echarts.getModel().getComponent("bmap").getBMap();
        let view = map.getViewport(eval(pointArr));
        let mapZoom = view.zoom > 2 ? view.zoom - 1 : view.zoom;
        let centerPoint = view.center;
        map.centerAndZoom(centerPoint, mapZoom);
      }, 200);

      // let echarts = this.$refs.mapRef.echarts;
      // let map = echarts.getModel().getComponent("bmap").getBMap();
      // let myGeo = new window.BMap.Geocoder();
      // // 将地址解析结果显示在地图上，并调整地图视野
      // myGeo.getPoint(
      //   this.province + this.city + this.district,
      //   function (point) {
      //     if (point) {
      //       map.centerAndZoom(point, 14);
      //     }
      //   },
      //   this.city
      // );
    },
    verifyHash() {
      this.hashFlag = !this.hashFlag;
    },
    handleViewPic(row) {
      this.attachList = [];
      this.reportList = [];
      const { qualificationUrl, realPics } = row;
      if (realPics&&realPics!=="null") {
        let realPicsTemp = JSON.parse(realPics)
        this.attachList = realPicsTemp.map((item) => item.url);
      }
      if (qualificationUrl&&qualificationUrl!=="null") {
        let qualificationUrlTemp = JSON.parse(qualificationUrl)
        if (qualificationUrlTemp){
          this.reportList = qualificationUrlTemp.map(
                  (item) =>  item.picUrl
          );
        }
      }
      if (this.attachList.length > 0 ||
              this.reportList.length > 0
      ) {
        this.picDialogVisible = true;
      } else {
        this.$message({
          message: "无图片",
          type: "warning",
        });
      }
    },
    handleViewPic1(path) {
      this.url = path;
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
    handleInput(val) {
      this.initMap();
    },
  },
};
</script>
<style>
.companies-map {
  position: relative;
  height: 100%;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.chain-block-box {
  position: absolute;
  top: 28px;
  left: 15px;
  z-index: 1099;
  padding: 5px 10px;
  background-color: aliceblue;
  cursor: default;
}
.hash-value {
  color: #409eff;
  cursor: pointer;
}
.hash-result {
  color: #67c23a;
  cursor: pointer;
}
.company-list-box {
  position: absolute;
  top: 28px;
  right: 20px;
  z-index: 1099;
}
.company-list {
  background-color: aliceblue;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .company-list {
    background: hsla(0, 0%, 100%, 0.35);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
}
.companies-map .company-list .box-card {
  width: 350px;
  background-color: inherit;
}
.companies-map .company-list .box-card .el-table__row {
  cursor: pointer;
}
/* .companies-map
  .company-list
  .box-card
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped.current-row
  td,
.el-table__body tr.current-row > td {
  background-color: #ffec8b;
} */
.companies-map .company-list .el-table__body tr.current-row > td {
  background-color: #ffec8b;
}

.companies-map .company-list .box-card .el-table tbody tr:hover > td {
  background-color: #409eff;
  color: white;
}
.el-image-viewer__close {
  color: white;
}
.el-image-viewer__wrapper{
  z-index: 9999999 !important;
}
</style>
